import axios, { AxiosInstance, AxiosError } from 'axios';
import isNil from 'lodash.isnil';
import { kottosHost } from "./host";

import { getLogoutJob } from '../services/app';

const WeMoTokenErrors = [
  'USER_EXPIRED_TOKEN',
  'USER_INVALID_TOKEN',
  'USER_NO_TOKEN',
  'AUTH_NO_IDENTITY',
  'AUTH_NO_TOKEN',
  'AUTH_NO_VALID_IDENTITY',
  'AUTH_EXPIRED_TOKEN',
];

function WeMoAxiosProxy(instance: AxiosInstance) {
  function setToken(token: string, identity?: string) {
    instance.defaults.headers['Authorization'] = `${identity || 'Worker'} ${token}`;
  }

  function setAcceptLanguage(locale: string) {
    instance.defaults.headers['Accept-Language'] = locale;
  }

  instance.interceptors.response.use(
    undefined,
    async (error: AxiosError<{ error?: { type?: string } }>) => {
      const errorType = error?.response?.data?.error?.type;

      if (!isNil(errorType) && WeMoTokenErrors.includes(errorType)) {
        getLogoutJob();
      } else return Promise.reject(error);
    }
  );

  return new Proxy(instance, {
    get(target, key, receiver) {
      if (key === 'setToken') return setToken.bind(instance);
      if (key === 'setAcceptLanguage') return setAcceptLanguage.bind(instance);
      else return Reflect.get(target, key, receiver);
    },
  }) as AxiosInstance & { setToken: typeof setToken; setAcceptLanguage: typeof setAcceptLanguage };
}


const kottos = WeMoAxiosProxy(axios.create({ baseURL: kottosHost }));

export default axios;
export { kottos };
