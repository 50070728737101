import jsBridge from '@utils/wemoJsBridge';

export const refreshToken = async () => {
    return jsBridge.postMessage<{ token: string }>({ action: 'REFRESH_TOKEN' });
};

export const getLanguage = async () => {
    return jsBridge.postMessage<{ language: 'en' | 'zh-tw' }>({ action: 'GET_LNG' });
};

export const getLoginJob = async () => {
    return jsBridge.postMessage<{ workerToken: string }>({
        action: "GET_LOGIN",
    })
};

export const getLogoutJob = async () => {
    return jsBridge.postMessage({
        action: "LOGOUT",
    });
}

export const getTakePhotoJob = async (photoImageLimit: number) => {
    return jsBridge.postMessage<{ photos: string[] }>({
        action: "TAKE_PHOTO",
        token: "",
        payload: { photoImageLimit }
    });
}

export const subscribeMessage = (action: "REMOTE_NOTIFICATION" | "USER_LOCATION", successAction: (result: any) => void, failAction?: (error: any) => void): string => {
    return jsBridge.subscribeMessage(
        { action: action },
        result => {
            typeof successAction === 'function' && successAction(result)
        },
        error => {
            typeof failAction === 'function' && failAction(error)
        }
    ) as string;
}


export const unsubscribeMessage = (action: "REMOTE_NOTIFICATION" | "USER_LOCATION", listenerId: string) => {
    return jsBridge.unsubscribeMessage({ action: action, listenerId: listenerId });
}