import React from "react";
import styled from "styled-components";
import Button from "@atoms/Button";
import { selectUser } from "@redux/user";
import { useSelector } from "react-redux";
import { themeGet } from "@styled-system/theme-get";
import { useTranslation } from "react-i18next";
import { getLogoutJob } from "~/services/app";

const Container = styled.div``;

const Name = styled.div`
  ${themeGet("fonts.headline2")}
  margin-bottom:12px;
`;

const Id = styled.div`
  ${themeGet("fonts.subtitle")}
  color: ${themeGet("colors.black")};
`;
const Phone = styled.div`
  ${themeGet("fonts.body2")}
  color: ${themeGet("colors.secVariant")};
`;
const Info = styled.div`
  background: white;
  padding: 12px 16px;
`;

const ButtonStyled = styled(Button)`
  margin: 16px;
  position: absolute;
  bottom: 60px;
`;

const Account: React.FC = () => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const logOut = () => {
    getLogoutJob();
  };

  return (
    <Container>
      <Info>
        <Name>{user.profile?.name}</Name>
        <Id>{user.profile?.id}</Id>
        <Phone>{user.profile?.phone}</Phone>
      </Info>
      <ButtonStyled
        label={t("biker.logout")}
        shape="round"
        color="primary"
        width="calc(100% - 32px)"
        onClick={logOut}
      />
    </Container>
  );
};

export default Account;
